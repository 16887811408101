<template>
    <div>
         <a-card style="margin-bottom:20px">
            <a-form layout="inline">
                
                <a-form-item class="dep" label="标题:">
                    <a-input v-model="title" placeholder="标题"></a-input>
                </a-form-item>
                <a-form-item>
                <a-button type="primary" icon="search" @click="handleClickSearch">
                    搜索
                </a-button>    
                </a-form-item>
            </a-form>
        </a-card>
        <a-card>
            <a-row style="text-align:right;margin-bottom:20px;">
                <a-button type="primary" @click="handleAdd">新增</a-button>
            </a-row>
            <a-table
                :columns="columns"
                :data-source="tableData"
                :row-key="(record) => record._id"
                :pagination="pagination"
                :loading="loading"
                @change="handleTableChange"
                >
                <template slot="qrcode" slot-scope="code">
                    <img :src="code" style="height:50px;width:50px;" @click="preImg(code)" />
                </template>
                <a-tag slot="type" slot-scope="text" color="blue">{{text===1?'单人':'多人'}}</a-tag>
                <template slot="label" slot-scope="label,record">
                    <a-tag v-for="d in record.label_name" :key="d" color="blue">{{d}}</a-tag>
                </template>
                <template slot="user" slot-scope="users">
                    <a-tag v-for="d in users" :key="d" color="blue">{{d}}</a-tag>
                </template>
                 <template slot="operation"
                    slot-scope="text,record">
                    <div class="operation-wrapper">
                        <a-button type="link"  @click="handleUpdate(record)">修改</a-button>
                        <a-button type='link' style="color:red;" @click="handleDelete(record)">删除</a-button>
                    </div>  
                </template>
            </a-table>
        </a-card>
        <a-modal v-model="visible" title="新增渠道码" @ok="handleSubmit">
            <a-form  :form="addForm">
                <a-form-item 
                    label="标题" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input
                        v-model="addForm.title"
                        placeholder="请输入标题"
                    />
                </a-form-item>
                <a-form-item 
                    label="渠道标识"
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input
                        v-model="addForm.state"
                        placeholder="渠道标识"
                    />
                </a-form-item>
                <a-form-item 
                    label="员工"
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-tree-select
                        v-model="addForm.user"
                        :tree-data="userList"
                        tree-checkable
                        :show-checked-strategy="SHOW_PARENT"
                        search-placeholder="Please select"
                        :replaceFields="{children:'child',title:'userid',key:'userid',value:'userid'}"
                        @search="handleSearch"
                    />
                </a-form-item>
                <a-form-item 
                    label="标签"
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                >
                    <a-tree-select
                        v-model="arrList"
                        style="width: 100%"
                        :tree-data="tagTree"
                        tree-checkable
                        :show-checked-strategy="SHOW_PARENT"
                        placeholder="请选择"
                        :replaceFields="{title:'name',key:'id',value:'tag_id'}"
                        @change="getSeleted"
                    />
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="showUpdate" title="修改渠道码" @ok="handleSubmitUpdate">
            <a-form  :form="updateObj">
                <a-form-item 
                    label="标题" 
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input
                        v-model="updateObj.title"
                        placeholder="请输入标题"
                    />
                </a-form-item>
                <a-form-item 
                    label="渠道标识"
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-input
                        v-model="updateObj.state"
                        placeholder="渠道标识"
                        :disabled="true"
                    />
                </a-form-item>
                <a-form-item 
                    label="员工"
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                    >
                    <a-tree-select
                        v-model="updateObj.user"
                        :tree-data="userList"
                        tree-checkable
                        :show-checked-strategy="SHOW_PARENT"
                        search-placeholder="Please select"
                        :replaceFields="{children:'child',title:'userid',key:'userid',value:'userid'}"
                        @search="handleSearch"
                    />
                </a-form-item>
                <a-form-item 
                    label="标签"
                    :label-col="{span:6}" 
                    :wrapper-col="{ span: 14 }"
                >
                    <a-tree-select
                        v-model="arrList"
                        style="width: 100%"
                        :tree-data="tagTree"
                        tree-checkable
                        :show-checked-strategy="SHOW_PARENT"
                        placeholder="请选择"
                        :replaceFields="{title:'name',key:'id',value:'tag_id'}"
                        @change="getSeleted2"
                    />
                </a-form-item>
            </a-form>
        </a-modal>
        <a-modal v-model="isPre" title="预览" width="300px" :footer="null" style="text-align:center;">
            <img :src="codeImg" style="width:200px;height:200px;">
            <a-button type="primary" style="margin-top:20px" @click="loadImg(codeImg)">下载</a-button>
        </a-modal>
    </div>
</template>
<script>
const columns = [
    {
      title: "二维码",
      dataIndex: "qr_code",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'qrcode' },
    },
    {
      title: "标题",
      dataIndex: "title",
      align: "center",
      ellipsis: true
    },
    {
      title: "类型",
      dataIndex: "type",
      align: "center",
      ellipsis: true,
      scopedSlots: { customRender: 'type' },
    },
    {
      title: "渠道标识",
      dataIndex: "state",
      align: "center",
      ellipsis: true,
    },
    {
      title: "归属员工",
      dataIndex: "user",
      align: "center",
      scopedSlots: { customRender: 'user' },
    },
    {
      title: "标签",
      dataIndex: "label",
      align: "center",
      scopedSlots: { customRender: 'label' },    
    },
    {
        title: "操作",
        width: 120,
        align: "center",
        ellipsis: true,
        scopedSlots: { customRender: "operation" },
    },
]
import {
    mapState
  } from 'vuex'
  import {downloadImage} from '@/utils/utils'
  import { TreeSelect } from 'ant-design-vue';
  const SHOW_PARENT = TreeSelect.SHOW_PARENT;
export default {
    name:'channel-code',
    data(){
        return{
            arr:[],
            showUpdate:false,
            visible:false,
            isPre:false,
            title:'',
            SHOW_PARENT,
            codeImg:'',
            addForm:{
                title:'',
                state:'',
                type:2,
                user:[],
                label:[]
            },

            columns,
            tableData:[],
            userList:[],
            arrList:[],
            loading:false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 10, //每页中显示10条数据
                showTotal: (total) => `共有 ${total} 条数据`, //分页中显示总的数据
            },
            updateObj:{}
        }
    },
    computed: {
        ...mapState(['mqttInit','tagList','tagTree'])
    },
    watch:{
        mqttInit:{
            handler(val){
                if(val){
                    this.getUserList()
                    this.handleClickSearch()
                }
            },
            immediate:true
        }
    },
    mounted(){
        // console.log(this.tagTree)
    },
    methods:{
        getSeleted(){
            if(this.arrList.length==0){
                return
            }
            let arr = []
            this.arrList.forEach(item=>{
                let index = this.tagTree.findIndex(obj=>obj.tag_id==item)
                if(index!==-1){
                    this.tagTree[index].children.forEach(k=>arr.push(k.tag_id))
                }else{
                    arr.push(item)
                }
            })
            this.addForm.label = arr
        },
        getSeleted2(){
            if(this.arrList.length==0){
                return
            }
            let arr = []
            this.arrList.forEach(item=>{
                let index = this.tagTree.findIndex(obj=>obj.tag_id==item)
                if(index!==-1){
                    this.tagTree[index].children.forEach(k=>arr.push(k.tag_id))
                }else{
                    arr.push(item)
                }
            })
            this.updateObj.label = arr
        },
        handleDelete(item){
            this.$confirm({
              title: '提示',
              content: `是否确认删除本条数据?`,
              onOk:()=>{
                  let payload = {
                    msgId:'delContactWay',
                    data: {
                        config_id:[item.config_id]
                    },
                };
                  this.$mqtt.doPublish(
                    {
                    pubTopic: "scrm/externalContact/delContactWay",
                    payload,
                    },
                    (topic, data) => {
                        if(data.code==200){
                            this.$message.info('删除成功')
                            this.handleSearchList()
                        }
                    },
                    this
                );
              }})
        },
        handleUpdate(item){
            this.arrList = []
            this.updateObj = {...item}
            if(item.label){
                this.arrList = item.label || []
            }
            this.showUpdate = true
        },
        handleSubmitUpdate(){
            if(!this.updateObj.title){
                this.$message.warning('请输入标题')
                return
            }
            if(this.updateObj.user.length===0){
                this.$message.warning('请选择员工，此为必须')
                return
            }
            let param = {
                config_id:this.updateObj.config_id,
                title:this.updateObj.title,
                user:this.updateObj.user
            }
            if(this.updateObj.label.length>0){
                param.label = this.updateObj.label
            }
            
            let payload = {
                msgId:'updateContactWay',
                data: param,
            };

            this.$mqtt.doPublish(
                {
                    pubTopic: "scrm/externalContact/updateContactWay",
                    payload,
                },
                (topic, data) => {
                    if(data.code==200){
                        this.showUpdate = false
                        this.updateObj = {}
                        this.handleSearchList()
                    }
                },
                this
            );
        },
        loadImg(){
            downloadImage(this.codeImg,'code.png')
        },
        preImg(img){
            this.codeImg = img
            this.isPre = true
        },
        handleGroupChange(e){
            this.getUserList()
            this.addForm.user = []
        },
        handleSearch(val){
            this.getUserList(val)
        },
        handleSelect(val){
            this.addForm.user=[val]
        },
        getUserList(name=''){
            let payload = {
                msgId:'searchWxUserByPageOnChannel',
                data: {
                    pageSize:20,
                    pageNum:1
                },
            };
            if(name){
                payload.data.name = name
            }
            // console.log(payload)
            this.$mqtt.doPublish(
                {
                    pubTopic: "scrm/weUser/searchWxUserByPage",
                    payload,
                },
                (topic, data) => {
                    // console.log(data)
                    if(data.code===200){
                        this.userList = data.data.res;
                    }
                },
                this
            );
        },
        handleTableChange(pagination){
            this.pagination = pagination
            this.handleSearchList()
        },
        handleClickSearch(){
            this.pagination.current = 1
            this.handleSearchList()
        },
        handleSearchList(){
            this.loading = true
            let payload = {
                msgId:'searchqrcode',
                data:{
                    pageSize:this.pagination.pageSize,
                    pageNum:this.pagination.current,
                    title:this.title
                }
            }
            this.$mqtt.doPublish(
                {
                pubTopic: "scrm/wxapi/searchqrcode",
                payload,
                },
                (topic, data) => {
                    // console.log(data)
                    if(data.success){
                        this.loading = false
                        this.tableData = data.res
                        this.pagination.total = data.count
                    }else{
                        this.loading = false
                        this.$message.error('查询失败')
                    }
                },
                this
            );
        },
        handleSubmit(){
            this.visible = false
            let {title,
                state,
                type,
                user,
                label} = this.addForm
                if(!title){
                    this.$message.warning('请输入标题，以便后期查询等操作')
                    return
                }
                if(user.length===0){
                    this.$message.warning('请选择员工，此为必须')
                    return
                }
                let obj = {title,state,type,user}
                if(label.length>0){
                    obj.label = label
                }
            let payload = {
                msgId:'addContactWay',
                data:obj
            }
            this.$mqtt.doPublish(
                {
                    pubTopic: "scrm/externalContact/addContactWay",
                    payload,
                },
                (topic, data) => {
                    this.$message.success('新增成功')
                    this.handleClickSearch()
                },
                this
            );
        },
        handleAdd(){
            this.arrList = []
            this.addForm ={
                title:'',
                state:'',
                type:2,
                user:[],
                label:[]
            }
            this.visible = true
        },

    }
}
</script>
<style lang="scss" scoped>
    .operation-wrapper{
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
</style>